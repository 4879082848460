/* reusable variables */
$animationDuration: 0ms;

.react-transition {
  animation-fill-mode: initial;
}

@-webkit-keyframes slidein-left {
  0% {
    transform: translateX(15rem);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slidein-left {
  0% {
    transform: translateX(15rem);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeUp {
  from {
    transform: translate3d(0, 100vh, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0vh, 0);
    opacity: 1;
  }
}

.swipe-up {
  animation-timing-function: linear;
  transform-origin: bottom center;
  animation-name: swipeUp;
  animation-duration: $animationDuration;
}

@keyframes swipeLeft {
  from {
    transform: translate3d(-1000px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeLeft;
  animation-duration: $animationDuration;
}

@keyframes swipeRight {
  from {
    transform: translate3d(1000px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeRight;
  animation-duration: $animationDuration;
}

@keyframes swipeDown {
  from {
    transform: translate3d(0, -100vh, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.swipe-down {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: swipeDown;
  animation-duration: $animationDuration;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: fadeIn;
  animation-duration: $animationDuration;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: fadeOut;
  animation-duration: $animationDuration;
}

@keyframes scaleIn {
  from {
    transform: translateY(-500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleIn;
  animation-duration: $animationDuration;
}

@keyframes scaleInRight {
  from {
    transform: translateY(-500px) translateX(500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleInRight;
  animation-duration: $animationDuration;
}

@keyframes scaleInLeft {
  from {
    transform: translateY(-500px) translateX(-500px) scale(0.01);
  }

  to {
    transform: scale(1);
  }
}

.scale-in-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: scaleInLeft;
  animation-duration: $animationDuration;
}

@keyframes dropIn {
  from {
    transform: scale(4) translateY(400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropIn;
  animation-duration: $animationDuration;
}

@keyframes dropInRight {
  from {
    transform: scale(4) translateY(400px) translateX(400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in-right {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropInRight;
  animation-duration: $animationDuration;
}

@keyframes dropInLeft {
  from {
    transform: scale(4) translateY(400px) translateX(-400px);
  }

  to {
    transform: scale(1);
  }
}

.drop-in-left {
  animation-timing-function: ease-in-out;
  transform-origin: bottom center;
  animation-name: dropInLeft;
  animation-duration: $animationDuration;
}

@keyframes flipInX {
  from {
    transform: rotateX(90deg);
  }

  70% {
    transform: rotateX(-10deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

.flip-in-x {
  animation-timing-function: linear;
  animation-name: flipInX;
  transform-style: preserve-3d;
  animation-duration: $animationDuration;
}

@keyframes flipInXReverse {
  from {
    transform: rotateX(-90deg);
  }

  70% {
    transform: rotateX(10deg);
  }

  to {
    transform: rotateX(0deg);
  }
}

.flip-in-x-reverse {
  animation-timing-function: linear;
  animation-name: flipInXReverse;
  transform-style: preserve-3d;
  animation-duration: $animationDuration;
}

@keyframes flipInY {
  from {
    transform: rotateY(90deg);
  }

  70% {
    transform: rotateY(-10deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.flip-in-y {
  animation-timing-function: linear;
  animation-name: flipInY;
  animation-duration: $animationDuration;
  transform-style: preserve-3d;
}

@keyframes flipInYReverse {
  from {
    transform: rotateY(-90deg);
  }

  70% {
    transform: rotateY(10deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

.flip-in-y-reverse {
  animation-timing-function: linear;
  animation-name: flipInYReverse;
  animation-duration: $animationDuration;
  transform-style: preserve-3d;
}

@keyframes rotateIn {
  from {
    transform: scale(0.1) rotate(180deg);
  }

  70% {
    /*transform:  rotate(10deg);*/
  }

  to {
    transform: rotate(0deg);
  }
}

.rotate-in {
  animation-timing-function: ease-in-out;
  animation-name: rotateIn;
  animation-duration: $animationDuration;
  transform-style: preserve-3d;
}

@keyframes rotateInReverse {
  from {
    transform: scale(0.1) rotate(-180deg);
  }

  70% {
    /*transform:  rotate(10deg);*/
  }

  to {
    transform: rotate(0deg);
  }
}

.rotate-in-reverse {
  animation-timing-function: ease-in-out;
  animation-name: rotateInReverse;
  animation-duration: $animationDuration;
  transform-style: preserve-3d;
}

@keyframes nutty {
  from {
    transform: scale(0.001) translateX(100px);
  }

  25% {
    transform: scale(0.25) rotateY(180deg) translateX(100px) translateY(100px);
  }

  50% {
    transform: scale(0.5) rotateY(0deg) rotateX(180deg) translateX(-100px)
      translateY(600px);
  }

  75% {
    transform: scale(0.75) rotateY(420deg) rotateX(420deg)
      translate(420px, 420px);
  }
}

.nutty {
  animation-timing-function: linear;
  animation-name: nutty;
  animation-duration: 1s;
  transform-style: preserve-3d;
}
