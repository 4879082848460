@import "variables";

.data-display-table {
    margin-top: 1%;
    max-width: 100vw;
    max-height: 60vh;
    overflow-x: scroll;
    overflow-y: scroll;
  .rdt_Table {
    .epa-active-element {
      border: none !important;
    }
  }
  .rdt_TableCol_Sortable {
    color: $epa-black !important;
    margin-right: 1em !important;
    font-size: 16.64px !important;
    font-weight: bold !important;
    white-space: normal !important;
    overflow: visible !important;
  }
  .button_header{
    background: none;
    outline: inherit;
    border: none;
    font-weight: bold !important;
  }
  .rdt_TableRow {
    border-top: solid 1px $epa-navy !important;
    border-bottom: solid 1px $epa-navy !important;
  }
  .rdt_TableRow:nth-child(odd) {
    background-color: #f0f0f0 !important;
  }
  .rdt_TableHead {
    position: sticky !important;
    top: 0px;
    z-index: 1;
    /* this comment is here to avoid a code smell for empty content */
  }
  .rdt_TableHeader {
    display: none;
  }
  .rdt_TableHeadRow {
    border-bottom: solid 2px $epa-navy !important;
    .rdt_TableCol {
      //min-width: 15em !important;
      overflow: hidden;
      text-overflow: ellipsis;
      //max-width: 30em;
      margin: 0 !important;
      @extend .bg-white;
      border: solid 1px $epa-white;
      :focus {
        outline: none;
        border: solid 4px #2672de;
        color: $epa-black !important;    
      }
      :focus-within{
        #icons {
          visibility: visible !important;
        }
        #bdfSortIcon {
          opacity: 1 !important;
        }

      }
      :hover:not(:focus) {
        color: $epa-black !important;
        #icons {
          visibility: visible !important;
        }
      }
    }
  }

  .rdt_TableBody {
    //height = 400px
  }

  .rdt_ExpanderRow {
    /* this comment is here to avoid a code smell for empty content */
  }
  .rdt_TableFooter {
    /* this comment is here to avoid a code smell for empty content */
  }
  .rdt_TableCell {
    font-size: 15.6px !important;
    //min-width: 15em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    //max-width: 30em;
  }
  .__rdt_custom_sort_icon__ {
    @extend .text-primary;
  }
}
