.Tooltip {
  position: absolute;
  padding: 6px;
  color: black;
  white-space: normal;
  width: 200px;
  background: white;
  font-size: small;
  z-index: 3;
}
