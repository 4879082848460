@import "../../../styles/variables";

.data-display-table {
  max-height: 100vh !important; /* Default height for small screens */
}

@media (min-height: 600px) {
  .data-display-table {
    max-height: 70vh !important;
  }
}

@media (min-height: 1300px) {
  .data-display-table {
    max-height: 72vh !important;
  }
}

@media (min-height: 1410px) {
  .data-display-table {
    max-height: 75vh !important;
  }
}

@media (min-height: 1700px) {
  .data-display-table {
    max-height: 76vh !important;
  }
}

@media (min-height: 1900px) {
  .data-display-table {
    max-height: 77vh !important;
  }
}

@media (min-height: 2000px) {
  .data-display-table {
    max-height: 80vh !important;
  }
}

@media (min-height: 2600px) {
  .data-display-table {
    max-height: 82vh !important;
  }
}

@media screen and (min-width: 1024px) {
  .side-nav {
    min-width: $side-nav-width-desktop;
    max-width: $side-nav-width-desktop;
  }
  .filter-panel {
    min-width: $side-nav-width-desktop;
    max-width: $side-nav-width-desktop;
  }
}

@media screen and (min-width: 1200px) {
  .side-nav {
    min-width: $side-nav-width-desktop-lg;
    max-width: $side-nav-width-desktop-lg;
  }
  .filter-panel {
    position: absolute;
    top: 0;
    left: $side-nav-width-desktop-lg;
    min-width: $side-nav-width-desktop-lg;
    max-width: $side-nav-width-desktop-lg;
    z-index: 2;
  }
}

.side-nav-height {
  min-height: 90vh;
}

.question-icon {
  position: relative;
  top: -0.65em;
  left: 0.5em;
}

.usa-alert--info:before {
  content: none !important;
}

.usa-alert:before {
  content: none !important;
}
