#spinner {
  .text-center {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 815;

    p {
      position: fixed;
      top: 55%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      height: 236px;
      margin: 0 auto;
      padding: 0;
      z-index: 17;
      width: 220px;
      background: rgb(240, 240, 240);
      border-radius: 5px;
    }

    button {
      position: fixed;
      top: 62%;
      right: 0;
      left: 0;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 1em;
      padding: 0.625em 1em;
      width: 140px;
    }
  }
}
