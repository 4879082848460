@import "../../styles/variables";

.subheader-wrapper {
  .logo-wrapper {
    img {
      max-width: 56px;
    }
    .program {
      font-size: 0.6rem !important;
    }
  }

  .custom-wrapper {
    @media only screen and (min-width: 1200px) {
      .desktop-lg\:width-mobile-md {
        width: 400px;
      }
    }
  }

  .link-accent-cool {
    a.usa-link {
      color: $eds-accent-cool;
    }
  }
  .usa-accordion__button span::after {
    content: none !important;
  }
}
