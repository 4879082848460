#glossary-page {
  .main-content {
    h1 {
      font-size: 39px;
    }
    p {
      font-size: 17px;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
    font-size: 16px;
  }
}
