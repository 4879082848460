@import "variables";

.header-container {
  .usa-nav {
    right: 0 !important;
    bottom: 0 !important;
    top: 0 !important;
    position: fixed !important;
    background: #fff !important;
    border-right: 0 !important;
    display: none !important;
    flex-direction: column !important;
    overflow-y: auto !important;
    padding: 1rem !important;
    width: 23.5em !important;
    z-index: 500 !important;

    ul {
      display: block !important;
      list-style-type: disc !important;
      margin-block-start: 1em !important;
      margin-block-end: 1em !important;
      margin-inline-start: 0px !important;
      margin-inline-end: 0px !important;
      padding-inline-start: 40px !important;
    }
  }

  .usa-nav__primary {
    margin-bottom: 0 !important;
    list-style-type: none !important;
    margin-top: 1.5rem !important;
    order: 2 !important;
  }

  .usa-nav__primary-item {
    border-top: 1px solid #dfe1e2 !important;
    border-top-color: rgb(223, 225, 226) !important;
    padding: 0.3em 0 0.3em 0 !important;

    button {
      font-size: 1.5em !important;
      font-weight: 500 !important;
    }
  }

  .usa-nav.is-visible {
    animation: slidein-left 0.3s ease-in-out !important;
    display: flex !important;
    justify-content: flex-start !important;
  }

  .usa-header {
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
      sans-serif !important;
    font-size: 1.06rem !important;
    line-height: 1.5 !important;
    z-index: 300 !important;
    background-color: #f2f6f8 !important;
    justify-content: flex-start !important;
  }

  .usa-header--basic .usa-nav-container {
    align-items: flex-end !important;
    justify-content: space-between !important;
    display: flex !important;
    float: right !important;
  }

  .usa-navbar {
    width: 100% !important;
    padding: 1em 0em 1em 1em !important;
  }

  .mainMenu > .usa-nav {
    min-width: 400px !important;
  }

  .usa-nav .usa-accordion {
    list-style-type: none !important;
    @extend .text-base-darkest;
    margin: 2em !important;
    padding: 0 !important;
    width: 100% !important;
    font-size: 1.06rem !important;
    line-height: 1.5 !important;
    position: relative !important;
    top: -4em !important;
  }

  .usa-nav__close {
    color: currentColor !important;
    display: block !important;
  }

  .usa-search {
    font-family: Source Sans Pro Web, Helvetica Neue, Helvetica, Roboto, Arial,
      sans-serif;
    margin: 0;
    font-size: 2em !important;
    line-height: 2em !important;
    position: relative !important;
    top: -1em !important;
    padding: 1em 0 2em 0 !important;
    width: 100% !important;
  }

  .usa-nav__close img {
    width: 0.75em !important;
    height: 0.75em !important;
  }

  .usa-nav__primary button[aria-expanded="false"] {
    background-image: url("../../public/images/icons/plus.svg"),
      linear-gradient(transparent, transparent) !important;
    background-size: 0.6em !important;
    position: relative;
    left: 0.5em !important;
  }

  .usa-nav__primary button[aria-expanded="true"] {
    background-image: url("../../public/images/icons/minus.svg"),
      linear-gradient(transparent, transparent) !important;
    position: relative;
    left: 0.5em !important;
  }

  .bg-secondary-darker {
    background: $epa-bg-secondary-darker;
  }

  .usa-footer__secondary-section {
    @extend .bg-base-lightest;
  }

  .usa-nav__submenu.usa-megamenu {
    a {
      z-index: 999 !important;
      @extend .text-base-dark;
      @extend .bg-base-lightest;
    }

    .usa-col {
      @extend .bg-base-lightest;
    }
  }

  .usa-nav__submenu {
    @extend .bg-base-lightest;
  }

  .usa-megamenu.usa-nav__submenu {
    left: -10% !important;
    padding-top: 0 !important;
    margin-top: 2% !important;
  }

  .usa-megamenu.usa-nav__submenu::after,
  .usa-nav__primary button[aria-expanded="true"] {
    @extend .bg-base-lightest;
    border-bottom: none !important;
  }

  .usa-nav__submenu-item {
    list-style: none;
    border-bottom: 1px solid #dfe1e2 !important;
    padding: 0.5em 0 0.5em 2em;
  }

  .usa-nav__primary .usa-current::after {
    @extend .bg-primary-vivid;
    border-radius: 99rem !important;
    content: "" !important;
    display: block !important;
    position: absolute !important;

    top: 0.25rem !important;
    width: 0.24em !important;
    left: -0.25em !important;
    height: 9em !important;
  }
}

.subheader-wrapper {
  @extend .bg-primary-dark;

  .usa-nav__primary button {
    font-family: "Source Sans Pro Web", serif !important;
    font-weight: bold !important;
    color: #fff;
  }

  .usa-nav__primary button[aria-expanded="false"] {
    background-image: url("../../public/images/icons/menu-item-expand.svg"),
      linear-gradient(transparent, transparent);
    background-repeat: no-repeat;
    background-size: 3rem;
    background-position: right;
  }

  .usa-nav__primary button[aria-expanded="true"] {
    background-repeat: no-repeat;
    background-size: 2rem;
    background-position: right;
  }

  .usa-nav__primary .usa-accordion__button span::after {
    color: #fff;
  }

  .usa-nav__primary > .usa-nav__primary-item > a {
    color: #fff;
  }

  .usa-nav__submenu {
    background-color: #162e51;
    top: 2.8em;
    width: 12em;
  }
  #extended-nav-section-last {
    right: 0.01em;
  }

  .usa-nav-container {
    max-width: 100% !important;
  }

  .usa-logo {
    font-size: 23px !important;
  }

  .usa-logo__text > h1 {
    line-height: 1.1;
  }
}

.usa-date-picker__calendar {
  margin-top: 1em !important;
}

.usa-date-picker__button {
  margin-left: 1em !important;
}

.usa-button--outline,
.usa-button--outline:hover {
  @extend .bg-white;
}

.bg-primary {
  background-color: #005ea2 !important;
}

.usa-checkbox,
.usa-radio {
  background: inherit;
}

.usa-checkbox__label::before {
  background: white;
}

.usa-accordion__content {
  p {
    max-width: inherit;
  }
}
