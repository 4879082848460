/* _variables.scss
 *    Contains all variables used throughout this project.
 *
 *    NOTE: to avoid duplication of variables, please keep them in alphabetical order
 *
 */
$eds-accent-cool: #00bde3;
$eds-accent-cool-hover: #28a0cb;
$eds-accent-cool-active: #07648d;
$epa-bg-secondary-darker: linear-gradient(to bottom, #520001 0%, #6c0810 100%);
$epa-black: #000;
$epa-blue-base: #005ea2;
$epa-darkslate-blue: #365b8f;
$epa-dark-gray: #666;
$epa-darker-gray: #565c65;
$epa-disabled-dark: #adadad;
$epa-generic-font-family: "Source Sans Pro", sans-serif;
$epa-gray: #ccc;
$epa-green: #008000;
$epa-info-base: #00bde3;
$epa-light-blue: #0071bc;
$epa-navy: #808080;
$epa-off-white: #f0f0f0;
$epa-primary-dark: #1a4480;
$epa-red: #950000;
$epa-teal: #6161ff;
$epa-white: #fff;
$epa-yellow: #f7b801;
$epa-error-dark: #b50909;
$side-nav-width-desktop: 332px;
$side-nav-width-desktop-lg: 389px;
