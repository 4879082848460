@import "../../styles/variables";

div.row.column1 {
  float: left;
  min-width: 50vw;
  position: relative;
  min-height: 100vh;

  height: auto;
}
.mainContent {
  max-height: fit-content;
  max-width: 100%;
}
.row {
  overflow: auto;
  min-width: 1400px;
  max-width: 2000px;
}
.sideNav {
  display: flex;
  flex-direction: column;
}

.skip-to-content-link {
  position: absolute;
  left: 0;
  background: $epa-light-blue;
  color: $epa-white !important;
  cursor: pointer;
  height: 30px;
  padding: 8px;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
}

@media screen and (min-width: 480px) {
  .epa-header img[title="Official EPA Logo"] {
    margin-left: 1em;
  }
}

@media screen and (min-width: 640px) {
  .epa-header img[title="Official EPA Logo"] {
    margin-left: 2em;
  }
}

@media screen and (min-width: 1400px) {
  .epa-header img[title="Official EPA Logo"] {
    margin-left: 5em;
  }
}

@media screen and (min-width: 1400px) {
  .epa-header button[data-testid="navMenuButton"] {
    margin-right: 4em;
    display: inline;
  }
}
