@import "react-responsive-carousel/lib/styles/carousel.min.css";

.hero-slideshow {
  position: relative;
  width: 100%;
}

.carousel-slider {
  height: 100%;
}

.slider-wrapper {
  height: 100%;
}

.slider {
  height: 100%;
}

.carousel_item {
  height: 100%;
}

.hero-slideshow__item {
  max-width: 100%;
  height: 100%;

  // Use CSS grid to set equal height slides.
  @supports (display: grid) {
    display: flex !important;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    position: relative !important;
  }
}

.hero-slideshow__nav {
  margin-top: -3rem;
  min-height: 3rem;
  position: relative;
}

.usa-hero--slideshow.usa-hero {
  min-height: 21.875rem;

  @media (max-width: 40rem) {
    background-image: none !important;
    min-height: 0;
  }

  .usa-hero__callout {
    > :last-child {
      margin-bottom: 0;
    }
  }

  // Use CSS grid to set equal height slides.
  @supports (display: grid) {
    flex-grow: 1;
    height: 100%;

    @media (max-width: 40rem) {
      .grid-container,
      .usa-hero__callout {
        height: 100%;
      }
    }
  }
}
