.filter-button {
  width: 100% !important;
  max-width: 100% !important;
  text-align: left !important;
  font-size: 1em !important;
}

.applied-filter {
  background: #005ea2 0% 0% no-repeat padding-box !important;
  color: #ffffff !important;
}
