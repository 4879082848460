.contact-us-wrapper {
  h1 {
    margin-bottom: 0px;
    font-size: 2.66rem;
    font-weight: 700;
  }

  h2 {
    font-size: 1.46rem;
    line-height: 0.9;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}
