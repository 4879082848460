@media screen and (min-width: 1400px) {
  .widescreen\:padding-x-10 {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10%;
    padding-right: 10%;
  }
}

.what-is-new-box {
  a.usa-link > img {
    vertical-align: middle;
  }
}

.what-is-new-wrapper {
  h2 {
    margin: 0;
    font-size: 32px;
  }
}

.data-card,
.visualization-gallery-card {
  td img {
    width: 130px !important;
    height: 65px;
  }
  #viz-gallery-icon {
    min-width: 35px !important;
  }
  #data-icon {
    min-width: 40px !important;
  }
  .header-link {
    text-decoration: none;
  }
}
