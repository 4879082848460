.tutorials-wrapper {
  h1 {
    font-size: 40px;
    font-weight: 700;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
  }
}
